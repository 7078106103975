<template>
  <!-- 培训记录 -->
  <div class="trainingRecord">
    <van-nav-bar title="培训记录"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight" />
    <van-search v-model="value"
                shape="round"
                placeholder="课程/讲师"
                @search="onSearch" />
    <div class="overflow_div">
      <van-tabs v-model="active"
                color="#2B8DF0"
                swipeable
                animated>
        <van-tab title="待培训">
          <van-pull-refresh v-model="isLoadingAwait"
                            @refresh="onRefreshAwait">
            <van-list v-model="loadingAwait"
                      :finished="finishedAwait"
                      finished-text="没有更多了"
                      @load="onLoadAwait">
              <div class="cellGroup await_border"
                   v-for="(item,index) in awaitList"
                   :key="index"
                   @click="toDetails">
                <van-cell class="first_cell"
                          title="标准固化模型"
                          label="讲师：卞耕方T">
                  <template #right-icon>
                    <img src="@/assets/img/toBeTrained.png"
                         alt="" />
                  </template>
                </van-cell>
                <van-cell class="last_cell"
                          title="培训时间：20201-05-20 12:32"
                          value="现场培训" />
              </div>
            </van-list>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="已培训">
          <van-pull-refresh v-model="isLoadingAlready"
                            @refresh="onRefreshAlready">
            <van-list v-model="loadingAlready"
                      :finished="finishedAlready"
                      finished-text="没有更多了"
                      @load="onLoadAlready">
              <div class="cellGroup already_border"
                   v-for="(item,index) in alreadyList"
                   :key="index"
                   @click="toDetails">
                <van-cell class="first_cell"
                          title="标准固化模型"
                          label="讲师：卞耕方T">
                  <template #right-icon>
                    <img src="@/assets/img/trained.png"
                         alt="" />
                  </template>
                </van-cell>
                <van-cell class="last_cell"
                          title="培训时间：20201-05-20 12:32"
                          value="现场培训" />
              </div>
            </van-list>
          </van-pull-refresh>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  data () {
    return {
      value: '',
      active: 0,
      isLoadingAwait: false,
      awaitList: [],
      loadingAwait: false,
      finishedAwait: false,
      isLoadingAlready: false,
      alreadyList: [],
      loadingAlready: false,
      finishedAlready: false,

    }
  },
  methods: {
    // 回退
    onClickLeft () {
      if (this.$route.query.fromType == '主页')
      {
        this.$router.push('/home')
      } else if (this.$route.query.fromType == '报名成功')
      {
        this.$router.push('/signUpSuccessfully')
      }
    },
    onClickRight () { },
    onLoadAwait () {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++)
        {
          this.awaitList.push(this.awaitList.length + 1);
        }

        // 加载状态结束
        this.loadingAwait = false;

        // 数据全部加载完成
        if (this.awaitList.length >= 20)
        {
          this.finishedAwait = true;
        }
      }, 1000);
    },
    onRefreshAwait () {
      setTimeout(() => {
        Toast('刷新成功');
        this.isLoadingAwait = false;
      }, 1000);
    },
    onLoadAlready () {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++)
        {
          this.alreadyList.push(this.alreadyList.length + 1);
        }

        // 加载状态结束
        this.loadingAlready = false;

        // 数据全部加载完成
        if (this.alreadyList.length >= 20)
        {
          this.finishedAlready = true;
        }
      }, 1000);
    },
    onRefreshAlready () {
      setTimeout(() => {
        Toast('刷新成功');
        this.isLoadingAlready = false;
      }, 1000);
    },
    toDetails () {
      this.$router.push({ path: '/trainingDetails', query: { active: this.active, fromType: this.$route.query.fromType } })
    },
    // 搜索
    onSearch () {

    }
  }
}
</script>

<style lang="less" scoped>
.trainingRecord {
  height: 100vh;
  overflow: hidden;
  background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    overflow: auto;
    height: calc(100% - 210px);
    // background: #fff;
    ::v-deep.van-tab {
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 500;
    }
    .await_border {
      border-left: 8px solid #ff8b00;
    }
    .already_border {
      border-left: 8px solid #2b8df0;
    }
    .cellGroup {
      margin: 20px 30px;
      border-radius: 8px;
      .first_cell {
        border-radius: 0 8px 0 0;
        padding: 0;
        padding-bottom: 20px;
        .van-cell__title {
          padding: 20px 30px;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          .van-cell__label {
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 48px;
            color: #666666;
          }
        }
        img {
          width: 126px;
          height: 126px;
        }
      }
      .last_cell::after {
        border-bottom: unset;
      }
      .last_cell {
        border-radius: 0 0 8px 0;
        .van-cell__title {
          flex: 2;
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 48px;
          color: #666666;
        }
        .van-cell__value {
          flex: 1;
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 48px;
          color: #999999;
        }
      }
    }
  }
}
</style>